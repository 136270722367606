<template>
  <div class="login-page">
    <h1 class="text-center">GUDANG</h1>
    <div class="login-box">
      <div v-if="!loggedIn">
        <h4 class="text-center">Login</h4>
        <form action="#" @submit.prevent="login">
          <div class="form-group">
            <label for="">Email</label>
            <input
              type="email"
              class="form-control"
              v-bind:class="{ 'is-invalid': validate.email.error }"
              name="email"
              id="email"
              v-model="email"
            />
            <div class="invalid-feedback">
              {{ validate.email.message }}
            </div>
          </div>

          <div class="form-group">
            <label for="password">Password</label>
            <input
              type="password"
              class="form-control"
              v-bind:class="{ 'is-invalid': validate.password.error }"
              name="password"
              id="password"
              v-model="password"
            />
            <div class="invalid-feedback">
              {{ validate.password.message }}
            </div>
          </div>
          <button type="submit" class="btn btn-primary w-100">Login</button>
        </form>
      </div>

      <div v-else>
        <p>Your Logged In</p>
        <button class="btn btn-secondary w-100" @click="logout">Logout</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  computed: {
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
  },
  data() {
    return {
      email: "",
      password: "",
      validate: {
        email: {
          error: false,
          message: "",
        },
        password: {
          error: false,
          message: "",
        },
      },
    };
  },
  methods: {
    login() {
      this.startLoading();
      this.$store
        .dispatch("Auth/login", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.stopLoading();
          this.$router.push({ name: "Index" });
        })
        .catch((error) => {
          this.stopLoading();
          this.setValidate(error.response.data);
        });
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>

<style>
.login-page {
  width: 95%;
  margin: 0 auto;
  padding-top: 50px;
  height: auto;
}
.login-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

@media only screen and (min-width: 768px) {
  .login-page {
    width: 50%;
  }
}
</style>
