<template>
  <div>
    <div v-if="loggedIn">
      <header class="header">
        <span></span>
        <router-link to="/">
          <span class="title">Gudang</span>
        </router-link>
        <span class="btn-logout" v-on:click="logout">Logout</span>
      </header>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeComponent",
  computed: {
    loggedIn() {
      return this.$store.getters["Auth/loggedIn"];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("Auth/logout");
      this.$router.push("Login");
    },
  },
};
</script>

<style lang="scss">
.header {
  width: 100%;
  height: 55px;
  background-color: #ffffff;
  position: fixed;
  top: 0px;
  z-index: 100;

  .title {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0px, -50%);
    color: #000000;
    font-size: 1.5em;
    font-weight: bold;
  }

  .btn-logout {
    position: absolute;
    top: 50%;
    right: 10px;
    color: #000000;
    transform: translate(0px, -50%);
    cursor: pointer;
  }
}
</style>
