import axios from 'axios';
import store from '.'

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('access_token') || null,
        user: localStorage.getItem('user') || null,
        loggedIn: localStorage.getItem('logged_in') || false,
    },
    getters: {
        loggedIn(state) {
            return state.loggedIn != false;
        },
        isDeveloper(state) {
            let isDeveloper = false;
            state.user.roles.forEach(element => {
                if (element.name === "developer") {
                    isDeveloper = true
                }
            });

            return isDeveloper != false;
        },
        isAdmin(state) {
            let isAdmin = false;
            state.user.roles.forEach(element => {
                if (element.name === "admin") {
                    isAdmin = true
                }
            });

            return isAdmin != false;
        }
    },
    mutations: {
        retrieveToken(state, token) {
            state.token = token
        },
        clearToken(state) {
            state.token = null
        },
        setLoggedIn(state, val) {
            state.loggedIn = val;
        },
        setUser(state, val) {
            state.user = val;
        }
    },
    actions: {
        login(context, request) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_API_URL + '/login', {
                    email: request.email,
                    password: request.password
                }).then(response => {
                    localStorage.setItem('access_token', response.data.token);
                    localStorage.setItem('logged_in', true);
                    context.commit('retrieveToken', response.data.token);
                    context.commit('setLoggedIn', true);
                    context.commit('setUser', response.data.data);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        logout(context) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('logged_in');
            context.commit('clearToken');
            context.commit('setLoggedIn', false);
        }, authorization(context) {
            return new Promise((resolve, reject) => {
                let token = store.state.Auth.token;
                let url = process.env.VUE_APP_API_URL + '/authorization';
                axios.get(url, {
                    params: {
                    },
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(response => {
                    localStorage.setItem('logged_in', true);
                    context.commit('setLoggedIn', true);
                    context.commit('setUser', response.data.data);
                    resolve(response);
                }).catch(error => {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('logged_in');
                    context.commit('clearToken');
                    context.commit('setLoggedIn', false);
                    reject(error);
                })
            })
        },
    }
}
