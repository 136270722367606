<template>
  <div class="loading" v-if="isLoading">
    <div class="text">Loading....</div>
  </div>
</template>

<script>
export default {
  name: "LoadingComponent",
  computed: {
    isLoading: function () {
      return this.$store.state.isLoading;
    },
  },
};
</script>

<style lang="scss">
.loading {
  z-index: 1000000;
  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  padding: 0px;
  margin: 0px;
  opacity: 0.8;
}
</style>
