import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/vue-moment'
import './plugins/vue-fontawesome'
import './plugins/vue-laravel-pagination'
import './plugins/vue-confirm-dialog'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './mixins/index'
import './mixins/loading'
import './mixins/validate'
import './mixins/reset-value'
import './filters/index'

import LoadingComponent from "./components/LoadingComponent.vue";
import HeaderComponent from "./components/HeaderComponent.vue";

Vue.component('LoadingComponent', LoadingComponent)
Vue.component('HeaderComponent', HeaderComponent)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  components:{
  },
  render: h => h(App)
}).$mount('#app')
