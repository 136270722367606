import axios from "axios";
import store from '../store'

const API_URL = process.env.VUE_APP_API_URL;

export default {
    namespaced: true,
    state: {
    },
    getters: {

    },
    mutations: {

    },
    actions: {
        list(context, data) {
            return new Promise((resolve, reject) => {
                let url = API_URL + "/item";
                axios.get(url, {
                    params: {
                        page: data.page,
                        search_keyword: data.searchKeyword,
                        sort_by: data.sortBy,
                        sort_type: data.sortType
                    },
                    headers: {
                        'Authorization': `Bearer ${store.state.Auth.token}`
                    }
                }).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        create(context, data) {
            return new Promise((resolve, reject) => {
                let url = API_URL + "/item";
                axios.post(url, data, {
                    headers: {
                        'Authorization': `Bearer ${store.state.Auth.token}`
                    }
                }).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        update(context, data) {
            return new Promise((resolve, reject) => {
                let url = API_URL + "/item" + '/' + data.id;

                axios.put(url, data, {
                    headers: {
                        'Authorization': `Bearer ${store.state.Auth.token}`
                    }
                }).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        destroy(context, data) {
            return new Promise((resolve, reject) => {
                let url = API_URL + "/item" + '/' + data.id;

                axios.delete(url, {
                    params: {
                    },
                    headers: {
                        'Authorization': `Bearer ${store.state.Auth.token}`
                    }
                }).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
    }
}