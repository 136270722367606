import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"
import auth from '../store/auth'
import store from '../store'
import item from './item'


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        audioSuccess: new Audio(require('../assets/audio/success.ogg')),
        audioError: new Audio(require('../assets/audio/error.ogg')),
        isLoading: false
    },
    mutations: {
        setLoading(state, val) {
            state.isLoading = val
        }
    },
    actions: {
        setLoading(context, val) {
            return this.commit('setLoading', val);
        },
        getStatistic() {
            return new Promise((resolve, reject) => {
                let token = store.state.auth.token;
                axios.get(process.env.VUE_APP_API_URL + '/statistic', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
    },
    modules: {
        Auth: auth,
        Item: item
    }
})
