import Vue from 'vue'

Vue.mixin({
    methods: {
        setValidate(responseError) {
            Object.keys(this.validate).forEach((key) => {
                this.validate[key].error = false;
                this.validate[key].message = "";
            });

            if (responseError.errors) {
                Object.keys(responseError.errors).forEach((key) => {
                    this.validate[key].error = true;
                    this.validate[key].message = responseError.errors[key][0];
                });
            }
        },
        resetValidate() {
            Object.keys(this.validate).forEach((key) => {
                this.validate[key].error = false;
                this.validate[key].message = "";
            });
        },
        setValidateValue(data) {
            let validate = {};
            Object.keys(data).forEach((key) => {
                let value = {};
                value={
                    error : false,
                    message:"null"
                };

                validate[key] = value;
            });

            return validate;
        }
    },
})