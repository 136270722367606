import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../views/auth/Login.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'Login' && !store.getters['Auth/loggedIn']) {
    next({ name: 'Login' });
  } else if (to.name == 'Login') {
    next();
  } else {
    store.commit('setLoading', true);
    await store.dispatch('Auth/authorization')
      .then(() => {
      })
      .catch((error) => {
        next({ name: 'Login' });
        console.log(error);
      });
    next();
    store.commit('setLoading', false);
  }
})

// router.afterEach((to) => {
//   if (to.name !== 'Login' && !store.getters['auth/loggedIn']) {
//     router.push('Login');
//   }
// })
export default router
