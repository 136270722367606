<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="toolbar-wrapper">
          <div class="add-button">
            <a
              v-if="isDeveloper"
              href="#"
              class="btn btn-primary btn-sm mb-3"
              @click="showModalCreate()"
              >Tambah</a
            >
          </div>

          <div class="filter-wrapper">
            <div class="foorm-group row">
              <label for="" class="col-sm-2"> Urutkan</label>
              <div class="col-sm-10">
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="sortModel"
                  @change="sort"
                >
                  <option :value="{ by: null, type: 'asc' }"></option>
                  <option :value="{ by: 'sku', type: 'asc' }">NAMA SKU A-Z</option>
                  <option :value="{ by: 'sku', type: 'desc' }">NAMA SKU Z-A</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group form-search-data">
            <input
              type="text"
              class="form-control"
              placeholder="Pencarian"
              v-model="searchKeyword"
              v-on:keyup="list"
            />
          </div>
        </div>

        <div class="list">
          <div class="list-header">
            <div class="number">NO</div>
            <div class="sku">NAMA SKU</div>
            <div class="name">NAMA BARANG</div>
            <div class="stock">EST STOK</div>
            <div class="last-update">LAST UPDATE</div>
            <div class="action" v-if="isDeveloper"></div>
          </div>

          <div
            class="item"
            v-bind:class="item.bg_color"
            v-for="(item, index) in items.data"
            :key="item.id"
          >
            <div class="item-wrapper number">
              <div class="title">NO</div>
              <div class="value">{{ index + 1 }}</div>
            </div>

            <div class="item-wrapper sku">
              <div class="title">NAMA SKU</div>
              <div class="value">{{ item.sku }}</div>
            </div>

            <div class="item-wrapper name">
              <div class="title">NAMA BARANG</div>
              <div class="value">{{ item.name }}</div>
            </div>

            <div class="item-wrapper stock">
              <div class="title">EST STOK</div>
              <div class="value">
                <input v-model="items.data[index].stock" @change="updateStock(item)" />
              </div>
            </div>

            <div class="item-wrapper last-update">
              <div class="title">LAST UPDATE</div>
              <div class="value">{{ item.updated_at }}</div>
            </div>

            <div class="item-wrapper action" v-if="isDeveloper">
              <div class="value">
                <font-awesome-icon
                  icon="edit"
                  @click="showModalEdit(item)"
                  v-if="isDeveloper"
                />
                <font-awesome-icon
                  icon="trash"
                  @click="destroy(item)"
                  v-if="isDeveloper"
                />
              </div>

              <div class="mobile-button" v-if="isDeveloper">
                <button
                  class="btn btn-primary btn-sm"
                  type="button"
                  @click="showModalEdit(item)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-danger btn-sm"
                  type="button"
                  @click="destroy(item)"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        <pagination
          class="pagination-wrapper"
          :limit="5"
          :show-disabled="true"
          :data="items"
          @pagination-change-page="list"
          ><span slot="prev-nav">&lt; Previous</span>
          <span slot="next-nav">Next &gt;</span>
        </pagination>
      </div>
    </div>

    <b-modal ref="modal-create" title="Tambah Data" hide-footer size="lg">
      <form action="#" @submit.prevent="create()">
        <div class="form-group">
          <label for="">SKU</label>
          <input
            type="text"
            class="form-control"
            v-bind:class="{ 'is-invalid': validate.sku.error }"
            name="name"
            v-model="item.sku"
          />
          <div class="invalid-feedback">
            {{ validate.sku.message }}
          </div>
        </div>
        <div class="form-group">
          <label for="">Nama</label>
          <input
            type="text"
            class="form-control"
            v-bind:class="{ 'is-invalid': validate.name.error }"
            name="description"
            v-model="item.name"
          />
          <div class="invalid-feedback">
            {{ validate.name.message }}
          </div>
        </div>

        <div class="form-group">
          <label for="">Jumlah Stock</label>
          <input
            type="text"
            class="form-control"
            v-bind:class="{ 'is-invalid': validate.stock.error }"
            name="name"
            v-model="item.stock"
          />
          <div class="invalid-feedback">
            {{ validate.stock.message }}
          </div>
        </div>

        <div class="form-group">
          <button type="submit" ref="button-submit" class="btn btn-primary">
            Simpan
          </button>
        </div>
      </form>
    </b-modal>

    <b-modal ref="modal-edit" title="Edit Data" hide-footer size="lg">
      <form action="#" @submit.prevent="update()">
        <div class="form-group">
          <label for="">SKU</label>
          <input
            type="text"
            class="form-control"
            v-bind:class="{ 'is-invalid': validate.sku.error }"
            name="name"
            v-model="item.sku"
          />
          <div class="invalid-feedback">
            {{ validate.sku.message }}
          </div>
        </div>
        <div class="form-group">
          <label for="">Nama</label>
          <input
            type="text"
            class="form-control"
            v-bind:class="{ 'is-invalid': validate.name.error }"
            name="description"
            v-model="item.name"
          />
          <div class="invalid-feedback">
            {{ validate.name.message }}
          </div>
        </div>

        <div class="form-group">
          <label for="">Jumlah Stock</label>
          <input
            type="text"
            class="form-control"
            v-bind:class="{ 'is-invalid': validate.stock.error }"
            name="name"
            v-model="item.stock"
          />
          <div class="invalid-feedback">
            {{ validate.stock.message }}
          </div>
        </div>

        <div class="form-group">
          <button type="submit" ref="button-submit" class="btn btn-primary">
            Simpan
          </button>
        </div>
      </form>
    </b-modal>

    <b-modal ref="modal-show" title="Lihat Data" hide-footer size="lg">
      <table class="table-show-on-modal">
        <tbody>
          <tr>
            <td>ID</td>
            <td>:</td>
            <td>{{ item.id }}</td>
          </tr>
          <tr>
            <td>Nama</td>
            <td>:</td>
            <td>{{ item.name }}</td>
          </tr>
          <tr>
            <td>Deskripsi</td>
            <td>:</td>
            <td>{{ item.description }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Item",
  components: {},
  data: function () {
    return {
      tableRowCount: 0,
      page: 1,
      searchKeyword: null,
      sortBy: null,
      sortType: "desc",
      sortModel: null,
      items: {},
      item: {
        id: null,
        name: null,
        sku: null,
        stock: null,
        description: null,
      },
      validate: {},
    };
  },
  computed: {
    isDeveloper() {
      return this.$store.getters["Auth/isDeveloper"];
    },
    isAdmin() {
      return this.$store.getters["Auth/isAdmin"];
    },
  },
  created() {
    this.validate = this.setValidateValue(this.item);
  },
  mounted() {
    this.list();
  },
  methods: {
    list(page = 1) {
      this.page = page;
      this.$store
        .dispatch("Item/list", {
          page: this.page,
          searchKeyword: this.searchKeyword,
          sortBy: this.sortBy,
          sortType: this.sortType,
        })
        .then((response) => {
          this.items = response.data;
          this.tableRowCount = response.data.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    show(item) {
      this.item = item;
      this.$refs["modal-show"].show();
    },
    create() {
      this.$store
        .dispatch("Item/create", this.item)
        .then((response) => {
          console.log(response);
          this.$refs["modal-create"].hide();
          this.list();
        })
        .catch((error) => {
          console.log(error);
          this.setValidate(error.response.data);
        })
        .finally(() => {});
    },
    update() {
      this.$store
        .dispatch("Item/update", this.item)

        .then((response) => {
          console.log(response);
          this.$refs["modal-edit"].hide();
          this.list();
        })
        .catch((error) => {
          console.log(error);
          this.setValidate(error.response.data);
        })
        .finally(() => {});
    },
    updateStock(item) {
      this.$store
        .dispatch("Item/update", item)

        .then((response) => {
          console.log(response);
          this.$refs["modal-edit"].hide();
          this.list();
        })
        .catch((error) => {
          console.log(error);
          this.setValidate(error.response.data);
        })
        .finally(() => {});
    },
    destroy(item) {
      console.log(item);
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.$store
              .dispatch("Item/destroy", item)
              .then((response) => {
                console.log(response);
                this.list();
              })
              .catch((response) => {
                console.log(response);
              });
          }
        },
      });
    },
    sort() {
      this.sortBy = this.sortModel.by;
      this.sortType = this.sortModel.type;
      this.list();
    },
    showModalCreate() {
      this.item = this.resetValue(this.item);
      this.resetValidate();
      this.$refs["modal-create"].show();
    },
    showModalEdit(item) {
      this.item = item;
      this.resetValidate();
      this.$refs["modal-edit"].show();
    },
  },
};
</script>

<style lang="scss"></style>
