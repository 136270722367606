import Vue from 'vue'
import store from '../store'

Vue.mixin({
    methods: {
        startLoading() {
            store.commit('setLoading', true);
        },
        stopLoading() {
            store.commit('setLoading', false);
        }
    }
})