import Vue from 'vue'

Vue.mixin({
    methods: {
        resetValue(variable) {
            var newVariable = {};
            Object.keys(variable).forEach((key) => {
                if(Array.isArray(variable[key]) == true){
                    newVariable[key]= [];
                }else{
                    newVariable[key]= null;
                };
            });

            return newVariable;
        }
    }
})