import Vue from 'vue'
import store from '../store'

Vue.mixin({
    methods: {
        makeToast(title, message, variant = 'primary', append = false) {
            this.toastCount++
            this.$bvToast.toast(message, {
                title: title,
                autoHideDelay: 5000,
                variant: variant,
                toaster:'b-toaster-bottom-left',
                appendToast: append
            })
        },
        playSound(name = "error") {
            if (name == 'error') {
                store.state.audioError.play()
            } 

            if(name=='success') {
                store.state.audioSuccess.play()
            }
        }
    },
})