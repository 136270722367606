<template>
  <div id="app">
    <LoadingComponent />
    <vue-confirm-dialog />
    <HeaderComponent />
    <transition name="fade">
      <div class="wrapper">
        <router-view />
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "assets/css/style.scss";
</style>
